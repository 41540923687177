<template>
    <div class="result-component" :style="componentStyle">
        <p class="result-component__title">
            {{ fields.titleResult }}
        </p>

        <div class="result-component__container">
            <div>
                <p class="result-component__number">{{ fields.firstNumber }}</p>
                <p class="result-component__description">{{ fields.firstNumberDescription }}</p>
            </div>
            <div>
                <p class="result-component__number">{{ fields.secondNumber }}</p>
                <p class="result-component__description">{{ fields.secondNumberDescription }}</p>
            </div>
            <div>
                <p class="result-component__number">{{ fields.thirdNumber }}</p>
                <p class="result-component__description">{{ fields.thirdNumberDescription }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'result-component',
        props: ['fields'],
        computed: {
            componentStyle(){
                if(! this.fields.backgroundResult) {
                    return {};
                }

                return {
                    backgroundColor: this.fields.backgroundResult.color,
                    backgroundImage: 'url(' + this.fields.backgroundResult.url + ')',
                };
            }
        }
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .result-component {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 50px 0 150px 0;
        color: white;

        @media screen and (max-width: 1200px) {
            padding: 50px 0 50px 0;
        }

        &__title {
            text-align: center;
            .Caslon-Medium;
            font-size: 4.0rem;
            padding-bottom: 100px;

            @media screen and (max-width: 1200px) {
                padding-bottom: 50px;
            }
        }

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 80%;
            max-width: 1000px;
            margin: 0 auto;
        }

        @media screen and (max-width: 1200px) {
            &__container {
                flex-direction: column;
                text-align: center;
            }
        }

        &__number {
            .Lato;
            font-weight: 300;
            font-size: 6.0rem;
            text-align: center;
        }

        &__description {
            text-align: center;
            .Lato;
            font-weight: 300;
            font-size: 1.5rem;
        }

        @media screen and (max-width: 1200px) {
            &__description {
                margin-bottom: 50px;
            }

        }
    }

</style>
