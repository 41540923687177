<template>
    <div
        class="HeroComponent"
        :style="componentStyle"
        :class="{ 'HeroComponent-details': projectDetailsPage}"
    >
        <article class="HeroComponent_content">
            <slot></slot>
        </article>
    </div>
</template>
<script>
    export default {
        name: 'hero-component',
        props: ['backgroundImage', 'projectDetailsPage'],
        computed: {
            componentStyle() {
                if (!this.backgroundImage) {
                    return {};
                }

                return {
                    backgroundColor: this.backgroundImage.color,
                    backgroundImage: 'url(' + this.backgroundImage.url + ')'
                }
            }
        }
    }
</script>
<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .HeroComponent {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        background-color: rgba(0, 0, 0, 0.05);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        color: @font-white;
    }

    .HeroComponent-details {
        height: 600px;
    }

    .HeroComponent_content {
        text-align: center;
        width: 80%;
        max-width: 1000px;
        margin: 0 auto;
        z-index: 2;
    }

    .HeroComponent_title {
        .Caslon-Medium;
        font-size: 8.0rem;
        padding-bottom: 20px;
    }

    .HeroComponent_description {
        .Lato;
        font-weight: 200;
        font-size: 2.2rem;
        line-height: 40px;
    }

    .HeroComponent_logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-bottom: 35px;
    }

    .HeroComponent_logoAdidas {
        .sprite;
        .sprite-logo-adidas;
        margin-right: 20px;
    }

    .HeroComponent_logoAdd {
        .sprite;
        .sprite-logo-add;
        margin-right: 20px;
    }

    .HeroComponent_logoLhs {
        .sprite;
        .sprite-logo-lhs;
    }

    .HeroComponent_contactUs {
        font-size: 1.1rem;
        letter-spacing: 1px;
        margin-top: 50px;
        display: inline-block;
        padding: 25px 25px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        color: @font-white;
        text-decoration: none;
        text-transform: uppercase;
        .Lato;
        transition: 200ms all ease-in-out;

        &:hover {
            background-color: @bg-white;
            color: @font-black;
        }
    }

    .HeroComponent_type {
        .Lato;
        font-size: 1.2rem;
        letter-spacing: 1.1px;
        line-height: 22px;
        text-transform: uppercase;
    }

    @media (max-width: @breakpoint-device-sd) {

        .HeroComponent {
            height: 400px;
        }

        .HeroComponent_title {
            .Caslon-Medium;
            font-size: 3.2rem;
            padding-bottom: 20px;
        }

        .HeroComponent_description {
            .Lato;
            font-weight: 200;
            font-size: 1.4rem;
            line-height: 25px;
        }

        .HeroComponent_contactUs {
            margin-top: 30px;
            padding: 15px 15px;
            line-height: 15px;
            font-size: 1.0rem;
            letter-spacing: 0.5px;
        }

        .HeroComponent_logoContainer {
            display: none;
        }

        .HeroComponent_content {
            width: 90%;
        }
    }

</style>
