export default {
    state: {
        displayBackNavigation: false,
    },
    /**
     * @param {boolean} newValue
     */
    displayBackNavigation(newValue) {
        this.state.displayBackNavigation = newValue;
    }
}
