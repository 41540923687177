import Vue from 'vue';

import App from '@/app/App';
import router from '@/app/plugins/routes.js';
import i18n from '@/app/plugins/i18n.js';
import VueAnalytics from 'vue-analytics'
import '@/app/plugins/scroll-to.js';


/**
 * Analytics
 */

Vue.use(VueAnalytics, {
    id: 'UA-102689703-1',
    router,
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: RUNTIME_CONFIG.site + '/' + RUNTIME_CONFIG.language  + route.path,
                title: document.title,
                location: window.location.href,
            }
        }
    }
});


/**
 * Vue
 */

const app = new Vue({
    el: '#app',
    router,
    scrollTo,
    i18n,
    template: '<App></App>',
    components: {App}
});

