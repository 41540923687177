<template>
    <div class="HeaderComponent">

        <article
            class="HeaderComponent_content"
            :class="{ 'HeaderComponent_content-active': serviceState.displayBackNavigation}"
        >
            <router-link
                class="HeaderComponent_link"
                :to="{
                    name: 'ProjectList'
                }"
            >
                {{ $t("header.allProjects") }}
            </router-link>
            <a href="https://www.lahautesociete.com/" target="_blank" class="HeaderComponent_logoLhsLink">
                <div class="HeaderComponent_logoLhs"></div>
            </a>
        </article>
    </div>
</template>
<script>
    import header from '@/app/services/header';

    export default {
        name: 'header-component',
        data (){
            return {
                serviceState: header.state,
            }
        }
    }
</script>
<style scoped lang="less" rel="stylesheet/less">

@import (reference) "~@/styles/main.less";

.HeaderComponent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 105px;
    z-index: 5;
    pointer-events: none;
}

.HeaderComponent_logoLhsLink,
.HeaderComponent_link {
    pointer-events: auto;
}

.HeaderComponent_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    .Lato;
}

.HeaderComponent_link {
    padding: 20px 0;
    text-align: right;
    text-decoration: none;
    color: @font-white;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 1.3px;
    transform: translateX(-500px);
    transition: 300ms all ease-out;

    &:before {
        content: "";
        margin-right: 20px;
        transition: 100ms all ease-out;
        display: block;
        .sprite;
        .sprite-white-arrow;
    }

    &:hover {

        &:before {
            transform: translateX(-20%);
        }
    }
}

.HeaderComponent_logoLhsLink {
    .sprite;
    .sprite-tiny-logo-lhs;
    transform: translateX(500px);
    transition: 300ms all ease-out;
    cursor: pointer;
}

.HeaderComponent_content-active {

    .HeaderComponent_link {
        transform: translateX(0%);
    }

    .HeaderComponent_logoLhsLink {
        transform: translateX(0%);
    }
}

</style>

