<template>
    <div class="TagsComponent">
        <article class="TagsComponent_container">
            <h2 class="TagsComponent_title">{{ $t('filter.title') }}</h2>
            <ul class="TagsComponent_tags">
                <li
                    :class="{'TagsComponent_tag-active': isAllActive}"
                    @click.prevent="selectAll()"
                    class="TagsComponent_tag"
                >All
                </li>
                <li
                    v-for="item in displayedItems"
                    :class="{'TagsComponent_tag-active': item.isActive}"
                    @click.prevent="toggleItem(item)"
                    class="TagsComponent_tag"
                >{{item.name}}
                </li>
            </ul>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'tags-component',
        props: ['items', 'selectedItem'],
        computed: {
            /**
             * Transform items list to collection of objects
             * @return {Array}
             */
            displayedItems() {
                return _.map(this.items, item => {
                    return {
                        isActive: this.isActive(item),
                        name: item,
                    }
                });
            },

            /**
             * Return true if there is no selected item
             * @return {boolean}
             */
            isAllActive() {
                return !this.selectedItem;
            }
        },
        methods: {
            /**
             * Return true if selectedItem contains item
             * @param item
             * @return {boolean}
             */
            isActive(item) {
                let selectedItem = this.selectedItem || false;
                return _.includes(selectedItem, item);
            },

            /**
             * Update item in selectedItem
             * and trigger event to parent component
             * @param item
             */
            toggleItem(item) {
                this.$emit('selectItem', item.name);
            },

            /**
             * Reset selectedItem
             * and trigger event to parent component
             */
            selectAll() {
                this.$emit('selectItem', false);
            }
        }
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .TagsComponent {
        padding-top: 50px;
    }

    .TagsComponent_container {
        display: flex;
        flex-direction: row;
        max-width: 1100px;
        width: 85%;
        margin: 0 auto;
        font-size: 1.1rem;
        .Lato;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.3px;
    }

    .TagsComponent_tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: -10px;
    }

    .TagsComponent_tag {
        position: relative;
        margin-bottom: 7px;
        color: @font-gray;
        cursor: pointer;
        padding: 10px 20px;
        user-select: none;
        transition: color 250ms;

        &:hover {
            color: @font-black;
        }

        &:after {
            content: '';
            border-bottom: 1px solid @font-black;
            position: absolute;
            bottom: 2px;
            right: 19px;
            left: 19px;
            height: 1px;
            opacity: 0;
            transition: opacity 250ms;
        }
    }

    .TagsComponent_tag-active {
        color: @font-black;
        &:after {
            opacity:1;
        }
    }

    .TagsComponent_title {
        color: @font-black;
        margin-right: 40px;
        min-width: 250px;
    }

    @media (max-width: @breakpoint-device-sd) {

        .TagsComponent_container {
            flex-direction: column;
            width: 95%;
            font-size: 1.0rem;
            letter-spacing: 1.3px;
        }

        .TagsComponent_title {
            margin-left: 20px;
            width: 100%;
            margin-bottom: 50px;
            box-sizing: border-box;
        }

    }

</style>
