<template>
    <div class="AbstractComponent">

        <article class="AbstractComponent_container">

            <ul class="AbstractComponent_description">
                <li v-for="(field, index) in descriptionItems" :key="index">
                    <span class="AbstractComponent_label">{{field.label}}</span>
                    <span v-html="field.value"></span>
                </li>
            </ul>

            <div class="AbstractComponent_list">
                <span class="AbstractComponent_label">Scope of work</span>
                <ul>
                    <li v-for="(item, index) in scopeItems" :key="index">{{item}}</li>
                </ul>
            </div>

        </article>

    </div>
</template>

<script>
    import dateFormat from 'date-fns/format';

    export default {
        name: 'abstract-component',
        props: ['fields'],
        computed: {

            /**
             * Scope of work items
             * Split lines
             * @return {string[]|Array}
             */
            scopeItems() {
                return this.fields.scope.split('\r\n');
            },

            /**
             * List of description items
             * @return {Array}
             */
            descriptionItems() {

                const fieldList = [
                    {
                        label: 'Launch',
                        source: fields => {
                            if (!fields.launchDate) {
                                return false;
                            }
                            return dateFormat(fields.launchDate.date, 'MM/DD/YYYY')
                        }
                    },
                    {
                        label: 'Markets',
                        source: fields => {
                            return fields.markets.join('<em>&nbsp;/&nbsp;</em>');
                        }
                    },
                    {
                        label: 'Stakeholders',
                        source: fields => {
                            return fields.stakeholders.join('<em>&nbsp;/&nbsp;</em>');
                        }
                    },
                    {
                        label: 'Link',
                        source: fields => {
                            if (_.isEmpty(fields.linkUrl)) {
                                return false;
                            }
                            return '<a href="' + fields.linkUrl + '" target="_blank">' + fields.linkUrl + '</a>';
                        }
                    },
                    {
                        label: 'Results',
                        source: fields => fields.results
                    },
                    {
                        label: 'Test',
                        source: fields => fields.test
                    },
                ];

                // Mapping
                let items = _.map(fieldList, field => {
                    return {
                        label: field.label,
                        value: field.source(this.fields)
                    };
                });

                // Remove empty values
                items = _.filter(items, item => !_.isEmpty(item.value));

                return items;
            }
        }
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .AbstractComponent {
        padding-bottom: 100px;
    }

    .AbstractComponent_container {
        display: flex;
        justify-content: space-between;
        width: 85%;
        max-width: 1000px;
        margin: 0 auto;
        letter-spacing: 0.5px;
        .Lato;
    }

    .AbstractComponent_description {
        min-width: 400px;
        max-width: 450px;
        font-size: 1.1rem;
        line-height: 15px;
        color: @font-gray;
        text-transform: uppercase;

        li {
            line-height: 30px;
        }
    }

    .AbstractComponent_label {
        font-weight: bold;
        padding-right: 20px;
        color: @font-black;
        text-transform: uppercase;
        line-height: 30px;
        font-size: 1.1rem;
    }

    .AbstractComponent_list {
        min-width: 430px;
        line-height: 30px;

        li {
            font-size: 1.1rem;
            color: @font-gray;
            text-transform: uppercase;
        }

        li:before {
            content: "— ";
            display: inline-block;
            color: @font-black;
            padding-right: 5px;

        }
    }

    @media screen and (max-width: 1000px) {

        .AbstractComponent_container {
            overflow: hidden;
            flex-direction: column;
            text-align: center;
        }

        .AbstractComponent_list {
            margin-top: 40px;
            width: 90%;
            min-width: 100%;
        }

        .AbstractComponent_description {
            width: 90%;
            min-width: 100%;
        }

    }


</style>
