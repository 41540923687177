import endpoint from './endpoint';

// Public API
export default {

    cache: {
        content: false,
        promise: null
    },


    /**
     * Return all contents
     * @param endpointConfig
     * @return {Promise}
     */
    getAll(endpointConfig) {
        if (this.cache.content) {
            return new Promise((resolve) => resolve(this.cache.content));
        }
        if (this.cache.promise) {
            return this.cache.promise;
        }
        this.cache.promise = endpoint.get('endpoint/' + endpointConfig.language + '/homepage/' + endpointConfig.site).then(results => {
            this.cache.content = results.data.data[0];
            return this.cache.content;
        });

        return this.cache.promise;
    },

}
