<template>
    <div class="ProjectDetailsPage">

        <transition name="fade" appear>
            <hero-component
                    :backgroundImage="project.heroImage"
                    :projectDetailsPage="true"
            >
                <h1
                        class="HeroComponent_title"
                        v-html="project.title"
                ></h1>

                <h2
                        class="HeroComponent_type"
                        v-html="project.projectType"
                >{{project.projectType}}</h2>
            </hero-component>
        </transition>

        <div class="ProjectDetailsPage_fakeSpacer" v-if="!isLoaded"></div>

        <transition :name="bodyTransitionName">

            <body-component
                    v-if="isLoaded"
                    :components="project.body"
            ></body-component>

        </transition>
    </div>
</template>
<script>
    import projects from '@/app/services/projects';
    import header from '@/app/services/header';
    import runtimeConfig from '@/app/services/runtime-config';

    import BodyComponent from '@/app/components/BodyComponent';
    import HeroComponent from '@/app/components/HeroComponent';

    export default {
        name: 'project-details-page',
        components: {
            BodyComponent,
            HeroComponent,
        },
        props: ['projectSlug'],
        data() {
            return {
                project: false,
                isLoaded: false,
                bodyTransitionName: 'fade-delayed'
            }
        },
        watch: {
            '$route'(to, from) {
                this.routeChange();
            }
        },
        methods: {
            /**
             * On route change
             */
            routeChange() {
                this.isLoaded   = false;
                let projectSlug = this.$route.params.projectSlug;

                // Overview from cache
                this.project = projects.getOverviewBySlugFromCache(runtimeConfig, projectSlug);

                // Get full project data
                projects.getBySlug(runtimeConfig, projectSlug).then(project => {
                    this.isLoaded = true;
                    this.project  = project;
                });

                // No body transition delay if loading from endpoint
                setTimeout(() => this.bodyTransitionName = 'fade', 5);
            },
            /**
             * On window load
             */
            resetScrollTop() {
                window.scrollTo(0, 0);
            }
        },
        created() {
            this.resetScrollTop();
            this.routeChange();
            header.displayBackNavigation(true);
        },
        beforeDestroy() {
            header.displayBackNavigation(false);
        }
    }
</script>
<style scoped lang="less" rel="stylesheet/less">
    .ProjectDetailsPage_fakeSpacer {
        height: 1000vh;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 500ms;
    }

    .fade-delayed-enter-active, .fade-delayed-leave-active {
        transition: opacity 500ms;
        transition-delay: 250ms;
    }

    .fade-enter,
    .fade-delayed-enter {
        opacity: 0;
    }

</style>
