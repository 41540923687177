import Vue from 'vue'
import VueI18n from 'vue-i18n'
import runtimeConfig from '@/app/services/runtime-config';

Vue.use(VueI18n);

// Ready translated locale messages
const messages = {
    en: {
        filter: {
            title: 'SHOW ME PROJECTS THAT INCLUDES',
        },
        header : {
            allProjects: 'All projects'
        },
    },
    fr: {
        filter: {
            title: 'Montrer les projets qui incluent'
        },
        header : {
            allProjects: 'Tous les projets'
        },
    }
};

// Create VueI18n instance with options
export default new VueI18n({
    locale: runtimeConfig.language,
    messages,
});


