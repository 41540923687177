<template>
    <div class="GalleryTwoImagesComponent">

        <section class="GalleryTwoImagesComponent_container">

            <article class="GalleryTwoImagesComponent_screen">

                <p class="GalleryTwoImagesComponent_title">
                    <strong>{{ fields.leftHeading }}</strong>
                    {{ fields.leftDeviceType }}
                </p>

                <p class="GalleryTwoImagesComponent_description">
                    {{ fields.leftDescription }}
                </p>

                <img class="GalleryTwoImagesComponent_image"
                     :src="fields.leftImage.url"
                     :alt="fields.leftHeading"
                >

            </article>

            <article class="GalleryTwoImagesComponent_screen">

                <p class="GalleryTwoImagesComponent_title">
                    <strong>{{ fields.rightHeading }}</strong>
                    {{ fields.rightDeviceType }}
                </p>

                <p class="GalleryTwoImagesComponent_description">
                    {{ fields.leftDescription }}
                </p>

                <img class="GalleryTwoImagesComponent_image"
                     :src="fields.rightImage.url"
                     :alt="fields.rightHeading"
                >

            </article>

        </section>

    </div>
</template>

<script>
    export default {
        props: ['fields'],
        name: 'gallery-two-images-component'
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .GalleryTwoImagesComponent {
        padding-top: 100px;
        background-color: rgba(60, 60, 60, 0.97);
        padding-bottom: 100px;
        .Lato;

    }

    .GalleryTwoImagesComponent_container {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }

    .GalleryTwoImagesComponent_screen {
        width: 300px;
        text-align: left;

    }

    .GalleryTwoImagesComponent_title {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.3px;
        color: @font-gray;
        margin-bottom: 20px;
        font-size: 1.1rem;
        line-height: 15px;


        strong {
            color: @font-white;
            padding-right: 10px;
        }
    }

    .GalleryTwoImagesComponent_description {
        font-weight: 300;
        line-height: 20px;
        color: @font-white;
        font-size: 1.4rem;
    }

    .GalleryTwoImagesComponent_image {
        max-width: 100%;
        display: block;
        margin: 0 auto;
        padding-top: 70px;
    }


    @media (max-width: 1000px) {

        .GalleryTwoImagesComponent_container {
            display: flex;
            flex-direction: column;
            justify-content: center;

        }

        .GalleryTwoImagesComponent_screen {
            width: 100%;
            padding-bottom: 40px;

        }

    }

</style>
