import Vue from 'vue';
import Router from 'vue-router';
import ProjectListPage from '@/app/pages/ProjectListPage';
import ProjectDetailsPage from '@/app/pages/ProjectDetailsPage';
import runtimeConfig from '@/app/services/runtime-config';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: runtimeConfig.routeBaseUrl,
    routes: [
        {
            path: '/',
            name: 'ProjectList',
            component: ProjectListPage
        },
        {
            path: '/project/:projectSlug',
            name: 'ProjectDetails',
            component: ProjectDetailsPage,
            props: true,
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }

});
