import axios from 'axios';

export default {
    cache: {},
    setSite: (site) => this.site = site,
    get(url) {
        if (url in this.cache) {
            return this.cache[url];
        }

        const promise = axios.get(url);

        this.cache[url] = promise;

        return promise;
    }
};
