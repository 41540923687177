<template>
    <div class="FooterComponent">

        <article class="FooterComponent_content">
            <img class="FooterComponent_owner" :src="contact.image" :alt="contact.name">
            <h2 class="FooterComponent_call">{{contact.heading}}</h2>
            <p class="FooterComponent_description">
                {{contact.description}}<br>
                {{contact.phone}} — <a :href="emailHref">{{contact.email}}</a>
            </p>
        </article>

        <article class="FooterComponent_footer">
            <a href="//www.lahautesociete.com" target="_blank">
                <div class="FooterComponent_logoLhs"></div>
            </a>
        </article>

    </div>
</template>

<script>
    export default {
        name: 'footer-component',
        props: ['contact'],
        computed: {
            emailHref() {
                return 'mailto:' + this.contact.email + ';';
            }
        },
        created(){
            console.log(this.contact.contactName);
        }
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .FooterComponent_description a {
        text-decoration: none;
        color: @font-white;
        padding-bottom: 8px;

        &:hover {
            border-bottom: 1px solid @font-white;
        }
    }

    .FooterComponent {
        background-image: url("./../../static/images/footer-bg.jpg");
        background-size: cover;
        background-position: center center;
    }

    .FooterComponent_content {
        .Lato;
        text-transform: uppercase;
        text-align: center;
        padding: 100px;
        color: @font-white;
    }

    .FooterComponent_call {
        font-size: 1.6rem;
        font-weight: 400;
        color: @font-tomato;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        line-height: 20px;
    }

    .FooterComponent_owner {
        width: 120px;
        height: 120px;
        margin-bottom: 40px;
    }

    .FooterComponent_description {
        font-size: 1.1rem;
        letter-spacing: 1px;
        line-height: 30px;
        font-weight: 200;
    }

    .FooterComponent_footer {
        text-align: center;
        padding: 25px 0;
        background-color: @bg-gray;
    }

    .FooterComponent_logoLhs {
        .sprite;
        .sprite-logo-lhs;
    }

    @media (max-width: @breakpoint-device-sd) {

        .FooterComponent {
            background-image: url("./../../static/images/footer-bg.jpg");
            background-size: cover;
            background-position: center center;
        }

        .FooterComponent_content {
            padding: 50px;
        }
    }

</style>
