<template>
    <div
        class="HighlightComponent"
        :class="{ 'HighlightComponent-textLeft': fields.textPosition == 'left'}"
        :style="componentStyle"
    >

        <section class="HighlightComponent_container">

            <article class="HighlightComponent_content">
                <h2
                    class="HighlightComponent_title"
                    v-html="fields.heading"
                ></h2>
                <p
                    class="HighlightComponent_description"
                    v-html="fields.contentText"
                >
                </p>
            </article>

        </section>

    </div>
</template>
<script>
    export default {
        name: 'highlight-component',
        props: ['fields'],
        computed: {
            componentStyle(){
                if(! this.fields.backgroundImage) {
                    return {};
                }

                return {
                    backgroundColor: this.fields.backgroundImage.color,
                    backgroundImage: 'url(' + this.fields.backgroundImage.url + ')',
                };
            }
        }
    }
</script>
<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .HighlightComponent {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 120px 0;
    }

    .HighlightComponent_container {
        display: flex;
        justify-content: flex-end;
        width: 80%;
        max-width: 1000px;
        margin: 0 auto;
    }

    .HighlightComponent_content {
        color: @font-white;
        width: 400px;
    }

    .HighlightComponent_title {
        .Caslon-Medium;
        font-size: 4.0rem;
        padding-bottom: 30px;
    }

    .HighlightComponent_description {
        .Lato;
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 28px;
    }

    .HighlightComponent-textLeft {

        .HighlightComponent_container {
            justify-content: flex-start;
        }
    }

    @media screen and (max-width: 1200px) {

        .HighlightComponent_container {
            justify-content: center;
        }

        .HighlightComponent-textLeft {

            .HighlightComponent_container {
                justify-content: center;
            }
        }

    }

</style>
