<template>
    <div class="BodyComponent">
        <component
            v-for="(component, index) in components"
            :key="index"
            :is="componentName(component)"
            :fields="component.fields"
        ></component>
    </div>
</template>
<style>
    .BodyComponent{
        background-color: white;
    }
</style>
<script>

    import _ from 'lodash';

    export default {
        name: "body-component",
        props: ['components'],
        methods: {
            /***
             * Component Name
             * @param component
             * @returns {string}
             */
            componentName(component) {

                // Nom par défaut
                let componentName = _.capitalize(component.type);


                // Si besoin de changer le nom du composant
                const componentsMap = {
                    galleryOneImage : 'GalleryOneImage',
                    galleryTwoImages : 'GalleryTwoImages',
                };

                if(component.type in componentsMap) {
                    componentName = componentsMap[component.type];
                }

                return componentName + 'Component';
            }
        },
        beforeCreate: function () {
            this.$options.components.HighlightComponent = require('@/app/components/bodyComponents/HighlightComponent').default;
            this.$options.components.GalleryComponent = require('@/app/components/bodyComponents/GalleryComponent').default;
            this.$options.components.HeadlineComponent = require('@/app/components/bodyComponents/HeadlineComponent').default;
            this.$options.components.AbstractComponent = require('@/app/components/bodyComponents/AbstractComponent').default;
            this.$options.components.GalleryOneImageComponent = require('@/app/components/bodyComponents/GalleryOneImageComponent').default;
            this.$options.components.GalleryTwoImagesComponent = require('@/app/components/bodyComponents/GalleryTwoImagesComponent').default;
            this.$options.components.ResultComponent = require('@/app/components/bodyComponents/ResultComponent').default;
        }
    }
</script>
