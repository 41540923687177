<template>
    <div class="ProjectListPage">

        <hero-component
                :backgroundImage="hero.backgroundImage"
        >
            <template v-if="hero">
                <article class="HeroComponent_logoContainer">
                    <a href="https://www.lahautesociete.com/" target="_blank">
                        <div class="HeroComponent_logoLhs"></div>
                    </a>

                </article>
                <h1 class="HeroComponent_title">{{hero.heading}}</h1>
                <p class="HeroComponent_description">{{hero.description}}</p>
                <a
                        href="#"
                        class="HeroComponent_contactUs"
                        v-scroll-to="{
                     el: '.FooterComponent',
                     duration: 400,
                     easing: 'ease-out',
                     offset: -200,
                 }"
                >
                    {{hero.cta}}
                </a>
            </template>
        </hero-component>

        <tags-component
                :items="features"
                :selectedItem="selectedFeature"
                :class="{'ProjectListPage_tags-hidden': !projectsLoaded}"
                @selectItem="selectFeature"
        ></tags-component>

        <section class="ProjectListPage_wrapper">

            <article class="ProjectListPage_container"
                     v-for="project in displayedProjects"
                     :key="project.id"
            >

                <div class="ProjectListPage_item"
                     ref="items"
                     :style="projectItemStyle(project)"
                >

                    <router-link
                            v-if="project.slug"
                            :to="{
                        name:'ProjectDetails',
                        params: {projectSlug: project.slug}
                    }"
                            class="ProjectListPage_link"
                    >

                        <h1 class="ProjectListPage_title">{{project.title}}</h1>
                        <p class="ProjectListPage_chapo">{{project.overviewChapo}}</p>
                        <h2 class="ProjectListPage_subtitle">{{project.overviewDescription}}</h2>

                    </router-link>
                </div>

            </article>

        </section>

    </div>
</template>
<script>
    import projects from '@/app/services/projects';
    import content from '@/app/services/content';
    import runtimeConfig from '@/app/services/runtime-config';
    import HeroComponent from '@/app/components/HeroComponent';
    import TagsComponent from '@/app/components/TagsComponent';
    import {TimelineMax} from "gsap";

    export default {
        name: 'project-list-page',
        props: ['backgroundImage'],
        components: {
            HeroComponent,
            TagsComponent
        },
        data() {
            return {
                projects: [],
                projectsLoaded: false,
                displayedProjects: [],
                features: [],
                selectedFeature: false,
                hero: false,
            };
        },
        methods: {
            /**
             * On window load
             */
            resetScrollTop() {
                window.scrollTo(0, 0);
            },

            /**
             * Project item style
             * @param project
             */
            projectItemStyle(project) {
                if (!project.overviewImage) {
                    return {};
                }

                return {
                    backgroundColor: project.overviewImage.color,
                    backgroundImage: 'url(' + project.overviewImage.url + ')'
                }
            },

            /**
             * Load data from endpoint
             */
            loadData() {
                content.getAll(runtimeConfig)
                    .then(content => {

                        // Hero content
                        this.hero = {
                            heading: content.heroHeading,
                            description: content.heroDescription,
                            backgroundImage: content.heroBackgroundImage,
                            cta: content.heroCta,
                        };

                        // Get projects list
                        return projects.getListBySlugs(runtimeConfig, content.projects);
                    })
                    .then(projectsList => {
                        this.projects = projectsList;
                        this.features = _.uniq(_.flatten(_.map(projectsList, project => project.features)));
                        this.selectFeature(false);
                        this.projectsLoaded = true;
                    });

            },

            /**
             * Update selected feature
             * @param feature
             */
            selectFeature(feature) {
                this.selectedFeature = feature;
                this.filterProjects();
            },

            /**
             * Update visible projects according to selected features
             */
            filterProjects() {
                this.displayedProjects = this.getSelectedProjects();
                this.cardAnimationProjects();
            },

            /**
             * Return selected projects according to selected features
             * @return {*}
             */
            getSelectedProjects() {
                // Display all
                if (!this.selectedFeature) {
                    return this.projects;
                }

                // Filter
                return _.filter(this.projects, project => {
                    return _.includes(project.features, this.selectedFeature);
                });
            },

            /**
             * Launch timeline on project
             */
            cardAnimationProjects() {
                this.projectAnimation = new TimelineMax();
                this.projectAnimation
                    .to(this.$refs.items, 0, {
                        alpha: 0,
                        y: 60
                    })
                    .staggerTo(this.$refs.items, 0.5, {
                        alpha: 1,
                        y: 0,
                        ease: Power2.easeOut
                    }, 0.2)
                    .play();
            }
        },
        created() {
            this.resetScrollTop();

            // Fake projects - loading
            this.displayedProjects = _.times(30, {});

            // Load real projects from endpoint
            this.loadData();
        }
    }
</script>
<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .ProjectListPage_wrapper {
        padding: 100px 0;
        width: 1400px;
        margin: 0 auto;
        font-size: 0;
    }

    .ProjectListPage_container {
        .Lato;
        overflow: visible;
        position: relative;
        height: 600px;
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }

    .ProjectListPage_item {
        overflow: visible;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: rgba(0, 0, 0, 0.05);
        position: absolute;
        width: 440px;
        height: 440px;
        font-size: 14px;
    }

    .ProjectListPage_link {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.05);
        transition: all 200ms ease-in;

        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    .ProjectListPage_link > * {
        max-width: 80%;
    }

    .ProjectListPage_title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 2.1rem;
        letter-spacing: 2px;
        padding-bottom: 8px;
        line-height: 25px;
        color: @font-white;
    }

    .ProjectListPage_chapo {
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.3px;
        .Lato;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 10px;
    }

    .ProjectListPage_subtitle {
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 20px;
        color: @font-white;
    }

    /* Modifiers blocs */

    /* BLOC 1 */

    .ProjectListPage_container:nth-child(6n+1) {
        height: 540px;
    }

    .ProjectListPage_container:nth-child(6n+1) .ProjectListPage_item {
        top: 0px;
        width: ~"calc(100% - 20px)";
        height: 500px;
    }

    /* BLOC 2 */

    .ProjectListPage_container:nth-child(6n+2) {
        height: 540px;
    }

    .ProjectListPage_container:nth-child(6n+2) .ProjectListPage_item {
        top: -40px;
        right: 0;
        width: ~"calc(100% - 20px)";
        height: 650px;
    }

    /* BLOC 3 */

    .ProjectListPage_container:nth-child(6n+3) {
        height: 480px;
    }

    .ProjectListPage_container:nth-child(6n+3) .ProjectListPage_item {
        top: 0;
        right: 20px;
    }

    /* BLOC 4 */

    .ProjectListPage_container:nth-child(6n+4) {
        height: 480px;
    }

    .ProjectListPage_container:nth-child(6n+4) .ProjectListPage_item {
        bottom: -70px;
        left: 20px;
    }

    /* BLOC 5 */

    .ProjectListPage_container:nth-child(6n+5) {
        height: 640px;
    }

    .ProjectListPage_container:nth-child(6n+5) .ProjectListPage_item {
        left: 0;
        width: ~"calc(100% - 20px)";
        height: 600px;
    }

    /* BLOC 6 */

    .ProjectListPage_container:nth-child(6n) {
        height: 640px;
    }

    .ProjectListPage_container:nth-child(6n) .ProjectListPage_item {
        bottom: 80px;
        left: 20px;
        width: ~"calc(100% - 20px)";
        height: 440px;
    }

    @media (max-width: 1450px) {

        .ProjectListPage_wrapper {
            width: 90%;
        }

    }

    @media (max-width: @breakpoint-device-sd) {

        .ProjectListPage_container {
            .Lato;
            height: 400px !important;
            width: 100%;
            display: block;
            margin: 0 auto 20px auto;
        }

        .ProjectListPage_wrapper {
            padding: 50px 0;
            width: 90%;
        }

        /* BLOC 1 */

        .ProjectListPage_container:nth-child(6n+1) {
            width: 100%;
            height: 100%;
        }

        .ProjectListPage_container:nth-child(6n+1) .ProjectListPage_item {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        /* BLOC 2 */

        .ProjectListPage_container:nth-child(6n+2) {
            width: 100%;
            height: 100%;
        }

        .ProjectListPage_container:nth-child(6n+2) .ProjectListPage_item {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        /* BLOC 3 */

        .ProjectListPage_container:nth-child(6n+3) {
            width: 100%;
            height: 100%;
        }

        .ProjectListPage_container:nth-child(6n+3) .ProjectListPage_item {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        /* BLOC 4 */

        .ProjectListPage_container:nth-child(6n+4) {
            width: 100%;
            height: 100%;
        }

        .ProjectListPage_container:nth-child(6n+4) .ProjectListPage_item {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        /* BLOC 5 */

        .ProjectListPage_container:nth-child(6n+5) {
            width: 100%;
            height: 100%;
        }

        .ProjectListPage_container:nth-child(6n+5) .ProjectListPage_item {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        /* BLOC 6 */

        .ProjectListPage_container:nth-child(6n) {
            width: 100%;
            height: 100%;
        }

        .ProjectListPage_container:nth-child(6n) .ProjectListPage_item {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

</style>
