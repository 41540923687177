import _ from 'lodash';
import endpoint from './endpoint';


// Public API
export default {

    cache: {
        overviews: false,
        details: {}
    },

    /**
     * Return all projects
     * @param endpointConfig
     * @return {Promise}
     */
    getAll(endpointConfig) {
        if (this.cache.overviews) {
            return new Promise((resolve) => resolve(this.cache.overviews));
        }
        return endpoint.get('endpoint/' + endpointConfig.language + '/projects?site=' + endpointConfig.site).then(results => {
            this.cache.overviews = results.data.data;

            return this.cache.overviews;
        });
    },

    /**
     * Return project by slug
     * @param endpointConfig
     * @param slug
     */
    getBySlug(endpointConfig, slug) {

        return this.getAll(endpointConfig).then(projects => {
            const currentProject = _.find(projects, {slug: slug});

            if (this.cache.details[slug]) {
                return new Promise((resolve) => resolve(this.cache.details[slug]));
            }
            return endpoint.get(currentProject.url).then(results => {
                this.cache.details[slug] = results.data;
                return this.cache.details[slug];
            });
        });
    },

    /**
     * Return project overview by slug from cache only
     * @param endpointConfig
     * @param slug
     */
    getOverviewBySlugFromCache(endpointConfig, slug) {
        if (!this.cache.overviews) {
            return false;
        }
        return _.find(this.cache.overviews, {
            slug: slug,
        });
    },

    /**
     * Return projects by slugs
     * @param endpointConfig
     * @param {array} slugs
     */
    getListBySlugs(endpointConfig, slugs) {
        return this.getAll(endpointConfig)
            .then(projects => {
                return _.map(slugs, slug => {
                    return _.find(projects, {slug: slug});
                });
            });
    },


}
