import { render, staticRenderFns } from "./GalleryTwoImagesComponent.vue?vue&type=template&id=928bcb4c&scoped=true&"
import script from "./GalleryTwoImagesComponent.vue?vue&type=script&lang=js&"
export * from "./GalleryTwoImagesComponent.vue?vue&type=script&lang=js&"
import style0 from "./GalleryTwoImagesComponent.vue?vue&type=style&index=0&id=928bcb4c&prod&scoped=true&lang=less&rel=stylesheet%2Fless&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "928bcb4c",
  null
  
)

export default component.exports