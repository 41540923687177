<template>
    <div class="PopinComponent">
        <div class="PopinComponent_closeButton"
             @click="closeItem()"
        ></div>

        <section class="PopinComponent_container">
            <BodyComponent :components="item.body" />
        </section>
    </div>
</template>

<script>
    import BodyComponent from '@/app/components/BodyComponent';

    export default {
        name: 'popin-component',
        components: { BodyComponent },
        props: ['item'],
        methods:{
            /***
             * Emit close item envent
             */
            closeItem() {
                this.$emit('close-item');
            }
        }
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .PopinComponent {
        position: fixed;
        top:0;
        left:0;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        transition: all 300ms ease-in;
        transform: translateX(0%);

    }

    .PopinComponent_container {
        height: 100vh;
        width: 100%;
        overflow: auto;
    }

    .PopinComponent_closeButton {
        position: absolute;
        top:30px;
        right: 60px;
        cursor: pointer;
        .sprite;
        .sprite-close;
    }

    .PopinComponent-hidden {
        transform: translateX(100%);
    }

</style>
