var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ProjectListPage"},[_c('hero-component',{attrs:{"backgroundImage":_vm.hero.backgroundImage}},[(_vm.hero)?[_c('article',{staticClass:"HeroComponent_logoContainer"},[_c('a',{attrs:{"href":"https://www.lahautesociete.com/","target":"_blank"}},[_c('div',{staticClass:"HeroComponent_logoLhs"})])]),_c('h1',{staticClass:"HeroComponent_title"},[_vm._v(_vm._s(_vm.hero.heading))]),_c('p',{staticClass:"HeroComponent_description"},[_vm._v(_vm._s(_vm.hero.description))]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                 el: '.FooterComponent',
                 duration: 400,
                 easing: 'ease-out',
                 offset: -200,
             }),expression:"{\n                 el: '.FooterComponent',\n                 duration: 400,\n                 easing: 'ease-out',\n                 offset: -200,\n             }"}],staticClass:"HeroComponent_contactUs",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm.hero.cta)+" ")])]:_vm._e()],2),_c('tags-component',{class:{'ProjectListPage_tags-hidden': !_vm.projectsLoaded},attrs:{"items":_vm.features,"selectedItem":_vm.selectedFeature},on:{"selectItem":_vm.selectFeature}}),_c('section',{staticClass:"ProjectListPage_wrapper"},_vm._l((_vm.displayedProjects),function(project){return _c('article',{key:project.id,staticClass:"ProjectListPage_container"},[_c('div',{ref:"items",refInFor:true,staticClass:"ProjectListPage_item",style:(_vm.projectItemStyle(project))},[(project.slug)?_c('router-link',{staticClass:"ProjectListPage_link",attrs:{"to":{
                    name:'ProjectDetails',
                    params: {projectSlug: project.slug}
                }}},[_c('h1',{staticClass:"ProjectListPage_title"},[_vm._v(_vm._s(project.title))]),_c('p',{staticClass:"ProjectListPage_chapo"},[_vm._v(_vm._s(project.overviewChapo))]),_c('h2',{staticClass:"ProjectListPage_subtitle"},[_vm._v(_vm._s(project.overviewDescription))])]):_vm._e()],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }