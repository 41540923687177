<template>
    <div class="GalleryOneImageComponent">

        <section class="GalleryOneImageComponent_container">

            <p class="GalleryOneImageComponent_title"
               v-if="fields.heading"
            >
                <strong>{{ fields.heading }}</strong>
                {{ fields.devicetype }}
            </p>

            <p class="GalleryOneImageComponent_description"
               v-if="fields.description" >
                {{ fields.description }}
            </p>

            <img
                v-if="fields.image"
                class="GalleryOneImageComponent_image"
                :src="fields.image.url"
                alt="Landing"
            >

            <div
                v-if="fields.videoVimeo"
                class="GalleryOneImageComponent_video"
            >

                <iframe :src="urlVimeo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

            </div>

            <div
                v-if="fields.videoYoutube"
                class="GalleryOneImageComponent_video"
            >

                <iframe :src="urlYoutube" frameborder="0" allowfullscreen></iframe>
            </div>

        </section>

    </div>
</template>
<script>
    export default {
        props: ['fields'],
        name: 'gallery-one-image-component',
        created() {
            if (this.fields.videoVimeo) {
                this.urlVimeo = "https://player.vimeo.com/video/" + this.fields.videoVimeo;
            }
            if (this.fields.videoYoutube) {
                this.urlYoutube = "https://www.youtube.com/embed/" + this.fields.videoYoutube;
            }

        }
    }
</script>
<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .GalleryOneImageComponent {
        padding: 100px 0;
        background-color: rgba(60, 60, 60, 0.97);

        &-lighter {
           background-color: rgba(255, 255, 255, 1);
        }
    }

    .GalleryOneImageComponent_container {
        max-width: 1000px;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        .Lato;
    }

    .GalleryOneImageComponent_title {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.3px;
        color: @font-gray;
        margin-bottom: 20px;
        font-size: 1.1rem;
        line-height: 15px;

        strong {
            color: @font-white;
            padding-right: 10px;
        }
    }

    .GalleryOneImageComponent_description {
        font-weight: 300;
        line-height: 20px;
        color: @font-white;
        font-size: 1.4rem;
    }

    .GalleryOneImageComponent_image {
        display: block;
        padding-top: 50px;
        max-width: 100%;
        margin: 0 auto;
    }

    .GalleryOneImageComponent_video {
        display: block;
        padding-top: 50px;
        margin: 0 auto;

        iframe {
            width: ~"calc(100vw - 20vw)";
            max-width: 1000px;
            max-height: 563px;
            height: ~"calc(56.25vw - 11vw)";
            display: block;
            margin: 0 auto;
        }
    }
</style>
