<template>
    <div class="App">
        <header-component></header-component>
        <router-view></router-view>
        <footer-component :contact="contact"></footer-component>
    </div>
</template>
<script>
    import HeaderComponent from '@/app/components/HeaderComponent';
    import FooterComponent from '@/app/components/FooterComponent';
    import projects from '@/app/services/projects';
    import content from '@/app/services/content';
    import runtimeConfig from '@/app/services/runtime-config';

    export default {
        name: 'app',
        components: {
            FooterComponent,
            HeaderComponent
        },
        data() {
            return {
                contact: {},
            }
        },
        created() {
            content.getAll(runtimeConfig).then(content => {
                const contact = content.contact[0];
                this.contact = {
                    name: contact.contactName,
                    image: contact.contactImage.url,
                    heading: contact.contactHeading,
                    description: contact.contactDescription,
                    phone: contact.contactPhone,
                    email: contact.contactEmail,
                }
            });
        }
    }
</script>

<style src="@/styles/main.less" lang="less"></style>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .App {
        margin: 0;
        padding: 0;
    }
</style>
