<template>
    <div class="HeadlineComponent">
        <p
            class="HeadlineComponent_description"
            v-html="fields.contentText"
        ></p>
    </div>
</template>

<script>
    export default {
        name: 'headline-component',
        props: ['fields'],
    }
</script>

<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .HeadlineComponent {
        .Lato;
        text-align: center;
        padding: 70px 0;
    }

    .HeadlineComponent_description {
        font-size: 2.4rem;
        width: 80%;
        max-width: 950px;
        margin: 0 auto;
        line-height: 42px;
    }

    @media (max-width: @breakpoint-device-sd) {

        .HeadlineComponent {
            .Lato;
            text-align: center;
            padding: 40px 0;
        }

        .HeadlineComponent_description {
            font-size: 1.7rem;
            width: 80%;
            margin: 0 auto;
            line-height: 30px;
        }
    }
</style>
