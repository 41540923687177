<template>
    <div class="GalleryComponent">

        <section class="GalleryComponent_container">

            <article
                class="GalleryComponent_items"
                v-for="(item, index) in items"
                :key="index"
            >
                <div
                    class="GalleryComponent_item"
                    @click="openItem(item)"
                >
                    <img
                        v-if="item.galleryOverviewImage"
                        :src="item.galleryOverviewImage.url"
                        :alt="item.title"
                        class="GalleryComponent_images"
                    >
                    <p class="GalleryComponent_description">
                        <strong>{{ item.title }}</strong>
                        {{ item.deviceType }}
                    </p>
                </div>

                <div v-if="item.isOpen">
                    <popin-component
                        :item="item"
                        @close-item="closeItem(item)"
                    ></popin-component>
                </div>
            </article>

        </section>

    </div>
</template>
<script>
    import header from '@/app/services/header';
    import PopinComponent from '@/app/components/PopinComponent';

    export default {
        name: 'gallery-component',

        components: {PopinComponent},
        props: ['fields'],
        data() {
            return {
                items: []
            }
        },
        methods: {

            /**
             * Open modal
             * @param item
             */
            openItem(item) {
                item.isOpen = true;
                this.lockScroll();
                header.displayBackNavigation(false);
            },

            /**
             * Close modal
             * @param item
             */
            closeItem(item) {
                item.isOpen = false;
                this.unlockScroll();
                header.displayBackNavigation(true);
            },

            /**
             * Lock scrollbar
             */
            lockScroll() {
                document.getElementsByTagName("body")[0].style.overflow = "hidden";
                document.getElementsByTagName("body")[0].style.height   = "100vh";
            },

            /**
             * Unlock scrollbar
             */
            unlockScroll() {
                document.getElementsByTagName("body")[0].style.overflow = "auto";
                document.getElementsByTagName("body")[0].style.height   = "";
            },

        },

        created() {
            this.items = _.map(this.fields.items, (item) => {
                return {
                    ...item,
                    isOpen: false
                };
            });
        },

        beforeDestroy() {
            this.unlockScroll();
        }

    }
</script>
<style scoped lang="less" rel="stylesheet/less">

    @import (reference) "~@/styles/main.less";

    .GalleryComponent {
        background-color: @bg-gray100;
    }

    .GalleryComponent_container {
        display: grid;
        box-sizing: border-box;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
        grid-auto-rows: minmax(500px, auto);
        grid-auto-flow: dense;
        padding: 165px 0;
        width: 90%;
        max-width: 1250px;
        margin: 0 auto;
    }

    .GalleryComponent_items {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.25rem;
        padding: 1rem;
        width: 100%;
        text-align: center;
        margin-bottom: 150px;
        transition: all 200ms ease;

        &:hover {
            img {
                box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.29);
            }
        }

        img {
            transition: all 200ms ease;
            max-width: 100%;
            margin-bottom: 60px;
        }
    }

    .GalleryComponent_item {
        cursor: pointer;
    }

    .GalleryComponent_description {
        .Lato;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.3px;
        color: @font-gray;
        font-size: 1.1rem;

        strong {
            color: @font-black;
            padding-right: 10px;
        }
    }

    @media (max-width: 1350px) {

        .GalleryComponent_container {
            column-width: 400px;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            grid-auto-rows: minmax(400px, auto);
        }


        .GalleryComponent_items {
            cursor: pointer;
            margin-bottom: 50px;
        }

    }

    @media (max-width: 900px) {

        .GalleryComponent_container {
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-auto-rows: minmax(400px, auto);
        }

        .GalleryComponent_items {
            cursor: pointer;
            margin-bottom: 30px;
        }

    }


</style>
